import React from "react";

export const ZZ10_0301_LANG = (formatMessage) => {
    let MULTI_LANG = {
        POPUP_MESSAGE_SUCCESS                   : formatMessage({id: "zz10_0301.success.contSave", defaultMessage: "계약 정보가 수정되었습니다."}),
        POPUP_MESSAGE_VALID                     : formatMessage({id: "zz10_0301.validation.fieldValid", defaultMessage: "@(0) 을(를) 입력해 주세요."}),
        SEARCH_LABEL_PROPERTY                   : formatMessage({id: "zz10_0301.title.searchProperty", defaultMessage: "프로퍼티명"}),
        SEARCH_LABEL_CONTRACT                   : formatMessage({id: "zz10_0301.title.sesrchContract", defaultMessage: "계약상태"}),
        SEARCH_LABEL_ALL                        : formatMessage({id: "zz10_0301.title.sesrchContractAll", defaultMessage: "전체"}),
        SEARCH_LABEL_Y                          : formatMessage({id: "zz10_0301.title.sesrchContractY", defaultMessage: "사용"}),
        SEARCH_LABEL_N                          : formatMessage({id: "zz10_0301.title.sesrchContractN", defaultMessage: "사용안함"}),
        SEARCH_LABEL_ACTIVE                     : formatMessage({id: "zz10_0301.title.searchActive", defaultMessage: "실사용여부"}),
        SEARCH_LABEL_COMN                       : formatMessage({id: "zz10_0301.title.searchComn", defaultMessage: "PMS 시스템 타입"}),
        SEARCH_BUTTON_SEARCH                    : formatMessage({id: "zz10_0301.buttom.searchSearch", defaultMessage: "검색"}),
        SEARCH_BUTTON_INIT                      : formatMessage({id: "zz10_0301.buttom.searchInit", defaultMessage: "초기화"}),
        CONT_BUTTON_SAVE                        : formatMessage({id: "zz10_0301.button.contSave", defaultMessage: "저장"}),
        CONT_BUTTON_CANCEL                      : formatMessage({id: "zz10_0301.button.contCancel", defaultMessage: "취소"}),
        GRID_HEADER_COMPANY_ID                  : formatMessage({id: "zz10_0301.title.contCompanyId", defaultMessage: "companyId"}),
        GRID_HEADER_PROPERTY_NAME               : formatMessage({id: "zz10_0301.title.contPropertyName", defaultMessage: "프로퍼티명"}),
        GRID_HEADER_AREA                        : formatMessage({id: "zz10_0301.title.contArea", defaultMessage: "지역"}),
        GRID_HEADER_CH_REG_DATE                 : formatMessage({id: "zz10_0301.title.contChannelRegDate", defaultMessage: "착수요청일자"}),
        GRID_HEADER_CH_START_DATE               : formatMessage({id: "zz10_0301.title.contChannelStateDate", defaultMessage: "채널연동일자"}),
        GRID_HEADER_CH_END_DATE                 : formatMessage({id: "zz10_0301.title.contChannelEndDate", defaultMessage: "사용종료일자"}),
        GRID_HEADER_TERM                        : formatMessage({id: "zz10_0301.title.contTerm", defaultMessage: "계약기간"}),
        GRID_HEADER_PMS                         : formatMessage({id: "zz10_0301.title.contPms", defaultMessage: "PMS 사용"}),
        GRID_HEADER_CONTRACT_MANAGER            : formatMessage({id: "zz10_0301.title.contContractManager", defaultMessage: "계약담당"}),
        GRID_HEADER_CONDITION                   : formatMessage({id: "zz10_0301.title.contCondition", defaultMessage: "계약조건"}),
        GRID_HEADER_FREE_CH                     : formatMessage({id: "zz10_0301.title.contFreeChannel", defaultMessage: "무료채널"}),
        GRID_HEADER_CH_CNT                      : formatMessage({id: "zz10_0301.title.contChannelCnt", defaultMessage: "계약 채널수"}),
        GRID_HEADER_ADDITION_CONTRACT           : formatMessage({id: "zz10_0301.title.contAdditionContract", defaultMessage: "추가계약"}),
        GRID_HEADER_LINKAGE_CH_CNT              : formatMessage({id: "zz10_0301.title.contLinkageChannelCnt", defaultMessage: "현 연동 \n채널수"}),
        GRID_HEADER_USAGE_STATUS                : formatMessage({id: "zz10_0301.title.contUsageStatus", defaultMessage: "계약여부"}),
        GRID_HEADER_ACTIVE                      : formatMessage({id: "zz10_0301.title.contActive", defaultMessage: "실사용 \n여부"}),
        GRID_HEADER_MODIFY                      : formatMessage({id: "zz10_0301.title.contModify", defaultMessage: "수정"}),
        GRID_BUTTON_EXCEL                       : formatMessage({id: "zz10_0301.button.contExcel", defaultMessage: "엑셀"}),
        GRID_SELECT_ITEM_Y                      : formatMessage({id: "zz10_0301.etc.gridSelectItemY", defaultMessage: "사용"}),
        GRID_SELECT_ITEM_N                      : formatMessage({id: "zz10_0301.etc.gridSelectItemN", defaultMessage: "사용안함"}),
        GRID_AUTO_SELECT_ITEM_ALL               : formatMessage({id: "zz10_0301.etc.gridAutoSelectItemAll", defaultMessage: "전체"}),
    }

    return MULTI_LANG;
};